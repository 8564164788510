<template>
  <div>
    <b-row>
      <b-col class="col-12 col-lg-8 col-xl-9 mb-2">
        <b-link @click="$router.push({ name: 'my_work_brands', params: { section: 'campaigns' } }).catch(() => {})"> <feather-icon icon="ChevronLeftIcon" /> {{ $t('tracking.goBack') }}</b-link>
        <b-row class="mt-1">
          <b-col class="col-md-6 col-12 d-flex align-items-stretch">
            <b-card class="overflow-hidden card-shadow">
              <div class="background-left-proposal default-color-background-tools">
              </div>
              <h6 class="title-tools">{{$t('creator.new_pitches')}}</h6>
              <h2>{{total_pitch.new_pitches }}</h2>
              <p class="text-muted">{{total_pitch.completed_pitches}} {{$t('creator.completed_pitches')}}</p>
            </b-card>
          </b-col>

          <b-col class="col-md-6 col-12 d-flex align-items-stretch">
            <b-card class="overflow-hidden card-shadow">
              <div class="background-left-proposal default-color-background-orders">
              </div>
              <h6 class="title-orders">{{$t('creator.accepted_pitches')}}</h6>
              <h2>{{total_pitch.acceptedt_pitches}}</h2>
              <p class="text-muted">{{total_pitch.rejected_pitches}} {{$t('creator.rejected_pitches')}}</p>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-card class="table-responsive" id="table-searcher-card">
              <b-col class="d-flex justify-content-between align-items-center flex-wrap p-1">
                <div class="container-mode-views">
                  <b-input-group class="input-group-merge coupons">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon"></feather-icon>
                    </b-input-group-prepend>
                    <b-form-input placeholder="Buscar" v-model="search"/>
                    <b-input-group-append is-text v-if="search.length > 0">
                      <feather-icon icon="XIcon" class="cursor-pointer" @click="search = ''"></feather-icon>
                    </b-input-group-append>
                  </b-input-group>
                  <div class="mt-10 d-flex responsive-button" v-if="checked_pitches.length !== 0">
                    <span>{{$t('creator.selected_pitches_check')}}: {{checked_pitches.length}}</span>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">
                  <b-button :disabled="Object.keys(pitch_selected).length === 0" variant="flat-secondary" @click="show_sider_filter = true"><b-icon icon="sliders"></b-icon> {{$t('search.filters')}}</b-button>                  
                  <b-button
                    :disabled="filters_layout.length === 0"
                    variant="flat-secondary"
                    class="button-filters"
                    @click="getPitchesAppliedByUUID()"
                  >
                  <b-img src="@/assets/images/icons/filterx.svg" class="img-filters"/>
                    {{$t('creator.clearFilter')}}
                  </b-button>
                </div>
              </b-col>
            </b-card>
          </b-col>
          <b-col>
            <div class="scrollable-table position-relative">
              <div v-if="format_data.length > 0">
                <b-table
                  :items="format_data"
                  id="table-searcher"
                  :fields="$t(`creator.table_casting_calls.pitchs_applied`)"
                  head-variant="light"
                  @row-clicked="rowTableClick"
                  :per-page="per_page"
                  :current-page="current_page"
                >
                  <template #head(id)>
                    <b-form-checkbox v-model="selected_all"></b-form-checkbox>
                  </template>
                  <template #cell(id)="data">
                    <div class="position-relative">
                      <div class="d-flex justify-content-between position-absolute">
                        <b-form-checkbox
                          class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
                          v-model="checked_pitches"
                          :value="data.item.id.uuid"
                          :ref="`checkbox_table_${data.item.id.id}`"
                        >
                        </b-form-checkbox>
                        <h6 class="id-table-searcher" :ref="`id_table_${data.item.id.id}`">{{data.item.id.id}}</h6>
                      </div>
                    </div>
                  </template>

                  <template #cell(sellers)="data">
                    <div class="d-flex align-items-center">
                      <b-avatar class="mr-1" :src="utils.getAssetUrl(data.item.sellers.avatar)"></b-avatar>
                      <div>
                        <div class="name-transactions d-flex">
                          <span class="text-overflow">{{data.item.sellers.name}} {{data.item.sellers.last_name}}</span>
                          <b-badge 
                            :variant="getColorPlan(data.item.plan.current_plan)"
                            :class="getColorPlan(data.item.plan.current_plan)"
                            pill
                            v-if="data.item.plan.net.length > 0 && data.item.plan.current_plan.toLowerCase() !== 'free'"
                          >{{data.item.plan.current_plan}}</b-badge>
                        </div>
                      </div>
                    </div>
                  </template>
                
                  <template #cell(status)="data">
                    <div class="d-flex align-items-center">
                      <b-badge pill :variant="getVariantStatus(data.item.status)" :class="getVariantStatus(data.item.status)">
                        {{$t('creator.status_pitch').find(response => response.value === data.item.status).text}}
                      </b-badge>
                    </div>
                  </template>

                  <template #cell(compensation)="data">
                    <div >$ {{utils.separatebycomma(data.item.compensation)}}</div>
                  </template>

                  <template #cell(submited_at)="data">
                    <div class="d-flex align-items-center">
                      {{utils.getDatev2(new Date(data.item.submited_at))}}
                    </div>
                  </template> 
                </b-table>
                <b-pagination 
                  v-model="current_page" 
                  :total-rows="format_data.length" 
                  :per-page="per_page"
                  class="d-flex justify-content-center"
                ></b-pagination>
              </div>
              
              <template v-else>
                <no-Results
                  :title= "$t('creator.noResulstPitches')"
                  :show_button= "false"/>
              </template>
              <b-card class="container-loader-searcher loader-table-cards" v-if="!loader_pitchs">
                <b-spinner class="spinner-table-searcher-opportunities"></b-spinner>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-12 col-lg-4 col-xl-3">
        <b-card v-if="Object.keys(pitch_selected).length > 0">
          <div class="d-flex align-items-center justify-content-between mb-1 line-divider-header">
            <div class="d-flex align-items-center mb-1">
              <b-avatar class="mr-1" :src="utils.getAssetUrl(pitch_selected.user.avatar)"></b-avatar>
              <div>
                <div class="name-transactions ">
                  <span>{{pitch_selected.user.first_name}} {{pitch_selected.user.last_name}}</span>
                  <b-badge 
                    :variant="getColorPlan(pitch_selected.user_networks[0].current_plan.name)"
                    :class="getColorPlan(pitch_selected.user_networks[0].current_plan.name)"
                    pill
                    v-if="pitch_selected.user_networks.length > 0 && pitch_selected.user_networks[0].current_plan.name.toLowerCase() !== 'free'"
                  >{{pitch_selected.user_networks[0].current_plan.name}}</b-badge>
                </div>
                <p class="text-muted m-0">
                  {{pitch_selected.user.country}}
                </p>
              </div>
            </div>
            <b-button v-if="pitch_selected.user_networks[0] && pitch_selected.user_networks[0].vanity_name" variant="light" class="mb-1" @click="$router.push({name: 'user_profile', params: {user: pitch_selected.user_networks.length > 0 ? pitch_selected.user_networks[0].vanity_name : ''}})">{{$t('creator.publicProfileCasting')}}</b-button>
          </div>
          <div v-if="!is_declining">
            <div v-if="pitch_selected.status === 'approved'">
              <b-button variant="blue-button" class="blue-button w-100 mb-2" @click="chatAction()">{{$t('creator.chatCreator')}}</b-button>
              <b-alert class="p-1" variant="success text-center" show>
                <p class="text-dark">{{$t('creator.acceptCreator')}}</p>
                <p class="avenir-medium text-dark">{{$t('creator.chat_creator_text')}}</p>
              </b-alert>
            </div>
            <div class="mb-1">
              <h5>Pitch:</h5>
              <div v-if="pitch_selected.type_pitch === 'text'">
                {{pitch_selected.pitch_description}}
              </div>
              <div v-else>
                <video :src="utils.isUrl(pitch_selected.video_url) ? pitch_selected.video_url : utils.getAssetUrl(pitch_selected.video_url)" class="video-pitch" controls></video>
              </div>
            </div>
            <div class="mb-1">
              <h5>{{$t('creator.askingPriceCasting')}}</h5>
              <p>$ {{utils.separatebycomma(pitch_selected.desired_payment)}}</p>
            </div>
            <div class="mb-1">
              <h5>{{$t('creator.connectionsStep')}}</h5>
              <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scroll-area-networks">
                <div 
                  v-for="(network, index) in pitch_selected.user_networks" 
                  :key="index" 
                  class="d-flex align-items-center avatar-networks-pitches"
                  @click="getLinkNetwork(network)"
                >
                  <b-avatar variant="ligth">
                    <i v-if="network.network !== 'blog' && network.network !== 'twitter'" :class="`${utils.getClassNetworkColor(network.network)} icon-network-profile`" />
                    <b-img v-else-if="network.network === 'blog'" :src="utils.getClassNetworkColor(network.network)" fluid class="icon-wordpress-profile"></b-img>
                    <div v-else-if="network.network === 'twitter'" class="icon-content-twitter-pitches">
                      <b-img :src="utils.getClassNetworkColor(network.network)" fluid class="icon-network-twitter-pitches" />
                    </div>    
                  </b-avatar>
                  <p class="m-0 ml-05">@{{network.username}}</p>
                </div>
              </vue-perfect-scrollbar>
            </div>
            <div class="mb-1" v-if="pitch_selected.answers.length > 0">
              <h5>{{$t('creator.suveryAnswers')}}</h5>
              <div v-for="(question, index) in pitch_selected.answers" :key="index" class="d-flex">
                <div class="mr-03">{{index + 1}}.-</div> 
                <div>
                  {{question.question.question}}
                  <p v-if="question.question.type_question === 'checkbox'" class="text-muted-gray">{{question.answer.join(', ')}}</p>
                  <p v-else class="text-muted-gray">{{question.answer}}</p>
                  <p v-if="question.question.type_question === 'media'"><a :href="utils.getAssetUrl(question.media)" target="_blank">{{ $t("creator.viewArchive") }}</a></p>
                </div>
              </div>
            </div>
            <div class="d-flex mb-1 justify-content-between" v-if="pitch_selected.status !== 'approved' && pitch_selected.status !== 'rejected'">
              <b-button variant="danger" class="spacing-button-pitch" @click="is_declining = true; scrollToTop()">{{$t('creator.decline')}}</b-button>
              <b-button variant="blue-button" class="spacing-button-pitch blue-button" @click="acceptRejectPitch(true)">{{$t('creator.accept')}}</b-button>
            </div>
          </div>
          <div v-else-if="is_declining">
            <h5>{{$t('creator.decline_pitch')}}</h5>
            <p>{{$t('creator.decline_pitch_suggest')}}</p>
            <validation-observer ref="updateProfile" #default="{ invalid }">
              <validation-provider rules="required">
                <b-form-group :label="$t('creator.reason')" label-for="input-reason" class="form-group-layout">
                  <v-select
                    v-model="declined_reason.reason"
                    :options="$t('creator.table_casting_calls.options_reasons')"
                    class="mb-1"
                  >
                  </v-select>
                </b-form-group>
              </validation-provider>
              <validation-provider rules="required">
                <b-form-group :label="$t('creator.message_seller_declined')" label-for="input-message" class="form-group-layout">
                  <b-form-input v-model="declined_reason.message"></b-form-input>
                </b-form-group>
              </validation-provider>
              <div class="d-flex justify-content-end mb-1">
                <b-button variant="light" @click="is_declining = false">{{$t('search.cancel')}}</b-button>
                <b-button variant="blue-button" :disabled="invalid" class="blue-button ml-1" @click="acceptRejectPitch()">{{$t('creator.submit')}}</b-button>
              </div>
            </validation-observer>
          </div>
        </b-card>
        <div v-else>
          <no-Results
            :title= "$t('creator.noResulstPitches')"
            :show_button= "false"/>
        </div>
      </b-col>
    </b-row>
    <sider-filter-pitch
      class="open-sidebar-search"
      @close_siderbar_filter="closeSiderFilter"
      :show_sider_filter="show_sider_filter"
      :filters_layout="filters_layout"
      @update_casting="getFormatTable"
      :casting_calls="casting_call"
      v-if="show_sider_filter"
    /> 
  </div>
</template>

<script>
import {
  BLink,
  BBadge,
  BSpinner,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCol,
  BRow,
  BCard,
  BTable,
  BAvatar,
  BImg,
  BButton,
  BFormCheckbox,
  BAlert,
  BPagination,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import noResults from '@/views/pages/proposals/stepper/noResults.vue';
import casting_calls_services from '@/services/casting_calls';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import pitch_service from '@/services/pitch_service';
import SiderFilterPitch from './siderFilterPitch.vue';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from 'axios';
import vSelect from 'vue-select';
import config from '@/services/config';
import { getColorPlan } from '@/libs/utils/icons';

export default {
  name: 'opportunities',
  components: {
    BLink,
    BAlert,
    BPagination,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BBadge,
    BSpinner,
    BImg,
    BButton,
    BCard,
    BCol,
    BRow,
    BTable,
    BFormCheckbox,
    BAvatar,
    noResults,
    VuePerfectScrollbar,
    SiderFilterPitch,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      getColorPlan,
      total_pitch: [],
      filters_layout: [],
      format_data: [],
      loader_pitchs: false,
      selected_all: null,
      checked_pitches: [],
      info_table: [],
      utils,
      show_sider_filter: false,
      cleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
      },
      casting_call: {},
      pitch_selected: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      interval_loading: null,
      is_declining: false,
      declined_reason: {},
      search: '',
      cancel_pitch_ws: null,
      current_page: 1,
      per_page: 10,
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    }
  },
  created() {
    this.getCastingCall();
  },
  methods: {
    chatAction() {
      casting_calls_services.createCastingCallChat(this.pitch_selected.uuid).then((response) => {
        this.$router.push({name: 'chat-proposal', params: {chat_uuid: response.uuid}});
      });
    },
    acceptRejectPitch(approved = false) {
      const data = approved ? {status: 'approved'} : {
        status: 'rejected',
        reject_reason: this.declined_reason.reason.value,
        reject_message: this.declined_reason.message,
      }
      pitch_service.acceptRejectPitch(this.pitch_selected.uuid, data).then(() => {
        this.pitch_selected.status = data.status;
        this.format_data.find((item) => item.id.uuid === this.pitch_selected.uuid).status = data.status;
        this.is_declining = false;
        this.declined_reason = {}
        if (data.status === 'rejected') {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('creator.status_declined'),
              icon: "AlertIcon",
              variant: "danger",
              text: this.$t('creator.status_declined_text'),
            },
          }); 
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('creator.status_approved'),
              icon: "AlertIcon",
              variant: "success",
              text: this.$t('creator.status_approved_text'),
            },
          }); 
        }
        
      });
    },
    getCastingCall() {
      this.loader_pitchs = false;
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      this.casting_call = this.$route.params.casting_call_data;
      this.getPitchesAppliedByUUID();
      this.getSummary(this.casting_call.uuid);
      this.loader_pitchs = true;
    },
    getLinkNetwork(profile) {
      let url = ''
      if (profile.network === 'facebook' && profile.link) url = profile.link
      url = utils.getURL(profile.network, profile.username, profile.is_channel)
      window.open(url, '_blank')
    },
    closeSiderFilter() {
      this.show_sider_filter = false
    },
    getPitchesAppliedByUUID(data = {query: undefined}) {
      this.loader_pitchs = false;
      this.filters_layout = []
      
      const CancelToken = axios.CancelToken;
      if (this.cancel_pitch_ws) this.cancel_pitch_ws()
      const self = this;
      axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/casting-calls/${this.casting_call.uuid}/get-pitches/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        data: {
          query: data.query
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_pitch_ws = c;
        })
      }).then((response) => {
        this.info_table = response.data.response.results;
        this.getFormatTable(this.info_table)
        this.pitch_selected = this.info_table.length > 0 ? this.info_table[0] : {};
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
        this.loader_pitchs = true;
      })
    },
    getSummary(uuid) {
      casting_calls_services.summaryCastingCall(uuid).then((response) => {
        this.total_pitch = response;
      });
    },
    getFormatTable(pitch, filters) {
      if (filters !== undefined) {
        this.filters_layout = filters
      }
      this.format_data = []
      pitch.map((item, index) => {
        this.format_data.push(
          {
            id: {id: index + 1, uuid: item.uuid},
            sellers: {avatar: item.user.avatar, name: item.user.first_name, last_name: item.user.last_name},
            plan: {net: item.user_networks, current_plan: (item.user_networks.length > 0) ? item.user_networks[0].current_plan.name : ''},
            status: item.status,
            compensation: item.desired_payment,
            submited_at: item.date_submitted
          });
      });
    },
    rowTableClick(data) {
      this.pitch_selected = this.info_table.find((item) => item.uuid === data.id.uuid);
      this.is_declining = false;
      this.declined_reason = {};
    },
    getVariantStatus(status) {
      if (status === 'approved') return 'light-success'
      if (status === 'rejected') return 'light-danger'
      if (status === 'submitted') return 'light-info'
      return 'light-secondary'
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.remove('d-none');
              checkbox.$el.classList.remove('checkbox-table-searcher');
            }
            if (idTable) idTable.classList.add('d-none');
          });
        }
      } else {
        for (let i = 1; i <= this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.add('d-none');
              checkbox.$el.classList.add('checkbox-table-searcher');
            }            
            if (idTable) idTable.classList.remove('d-none');
          });
        }
      }
    },
    checkedAll() {
      this.checked_pitches = [];
      let in_array = 0;
      this.info_table.forEach((item, index) => {
        if ((in_array < this.per_page) && (index <= (this.per_page * this.current_page) && index >= (this.per_page * (this.current_page - 1)))) {
          this.checked_pitches.push(item.uuid)
          in_array += 1
        }
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
  watch: {
    checked_pitches(val) {
      this.checkCheckboxClasses(val);
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_pitches = [];
    },
    search(val) {
      this.getPitchesAppliedByUUID({query: val.length === 0 ? undefined : val})
    },
    current_page() {
      this.selected_all = null;
    }
  }
}
</script>
<style scoped>
.img-filters {
  width: 17px;
}
.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-muted-gray {
  color: rgb(178, 178, 178);
}
.video-pitch {
  width: 100%;
  border-radius: 1em;
}
.ml-05 {
  display: block;
  margin-left: 0.4em !important;
}
.mr-03 {
  margin-right: 0.3em;
}
.scroll-area-networks {
  position: relative;
  margin: auto;
  width: 100%;
  min-height: 50px;
  max-height: 200px;
}
.avatar-networks-pitches {
  padding: 0.3em;
}
.avatar-networks-pitches:hover {
  background-color: rgb(242, 241, 241);
  cursor: pointer;
  transition: all 300ms;
}
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.icon-network-twitter-pitches {
  width: 15px;
  height: 15px;
}
.icon-content-twitter-pitches {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.mt-10 {
  margin-top: 10px !important;
}
.button-filters {
  margin-left: 5px;
}
.button-filters:focus {
  margin-left: 5px;
  background-color: transparent !important;
}
.sidebar-pitches-filter > .b-sidebar {
  width: 400px !important;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgb(255, 255, 255) !important;
  z-index: 1000;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-table-searcher-opportunities {
  height: 6em;
  width: 6em;
  color: #acacac;
}
.background-left-proposal {
  width: 10px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0px;
}
.title-tools {
  font-weight: bolder;
  color: #1ABC9C;
}
.title-orders {
  font-weight: bolder;
  color: #9B59B6;
}
.default-color-background-tools {
  background: linear-gradient(180deg, #50D0AD 0%, #29809A 100%);
}
.default-color-background-orders {
  background: linear-gradient(180deg, #ef98ff 0, #bf83ff 25%, #8f6cd8 50%, #6355b0 75%, #3d3f8b 100%);
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
}
.spacing-button-pitch{
  display: flex;
  width: 48%;
  justify-content: center;
}
.line-divider-header{
  border-bottom: 2px solid rgb(206, 212, 218) !important;
}
#table-searcher-card .card-body {
  padding: 0;
}
.table-responsive {
  overflow: visible;
}
#table-searcher td {
  border-bottom: 1px solid #eff3f6;
}
#table-searcher tr {
  background-color: white;
}
#table-searcher tr:hover {
  background-color: #EFF3F6;
  cursor: pointer;
  transition: all 100ms;
}
.thead-light th {
  background-color: white !important;
}
.checkbox-table-searcher {
  display: none !important;
}
#table-searcher tr:hover .checkbox-table-searcher {
  display: block !important;
}
#table-searcher tr:hover .id-table-searcher {
  display: none;
}
.card-shadow:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.card-shadow{
  width: 100%;
}
.open-sidebar-search > .b-sidebar-right {
animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
</style>